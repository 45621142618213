<template>
  <div class="box">
    <div class="box-header">
      <h4>{{ pageTitle }}</h4>
    </div>
    <model-form
      v-if="model"
      :submit-text="pageTitle"
      :model="model"
      :is-edit="true"
      @on-submit="createModel"
    >
    </model-form>
    <placeholder-form v-else></placeholder-form>
  </div>
</template>

<script>
import ModelForm from './components/_Form'
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'
import flatry from '@admin/utils/flatry'
import CouponService from '@admin/services/CouponService'
export default {
  name: 'editCoupon',
  data () {
    return {
      pageTitle: '编辑优惠劵',
      model: null
    }
  },

  components: {
    ModelForm,
    PlaceholderForm
  },

  async created () {
    const { data } = await flatry(CouponService.edit(this.$router.currentRoute.query.id))

    if (data) {
      this.model = data.data
    }
  },

  methods: {
    async createModel (model, success, callback) {
      const { data } = await flatry(CouponService.edit(model.id, model))
      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({
          path: '/coupon/update',
          query: { id: data.data }
        })
        success()
      }

      callback()
    }
  }
}

</script>
<style lang='' scoped>

</style>
